import React, { useContext, useEffect, useState } from 'react';
import { Corretor } from 'smart-imob-types';
import AppContext from '../../../context';
import { UsersView } from './view';
import { Spin } from 'antd';

export const UsersPage = props => {
  const { match } = props;

  const context = useContext(AppContext);

  const userId = match.params?.id_user;

  const [user, setUser] = useState<Corretor | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
      });
      const url = `${process.env.REACT_APP_API_URI}/corretores/${userId}?${params.toString()}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response.status}`);
      }

      const data = await response.json();
      setUser(data);
    };
    fetchData();
  }, [userId]);

  if (!user) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          padding: '20px 0',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  return <UsersView usuario={user} />;
};

import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Cliente, PermissoesInterface } from 'smart-imob-types';
import styled from 'styled-components';
import { CountNClientes } from './CountNClientes';
import { WhatsIcon } from './WhatsIcon';
import { Button } from '../../../components/buttons/buttons';
import FeatherIcon from 'feather-icons-react';
import {
  Table,
  Popover,
  Empty,
  Popconfirm,
  Typography,
  Progress,
  Checkbox,
  Tag,
  Modal,
  Tooltip,
  Divider,
  message,
} from 'antd';
import PopoverConjuge from '../popovers/popover_conjuge';
import { TableWrapper } from '../../styled';
import { UserTableStyleWrapper } from '../../pages/style';
import { AvatarImovel } from './AvatarImovel';
import antdTableLocale from '../../../config/locale/antd-table';
import { LikeOutlined, DislikeOutlined, CheckSquareOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { ReactComponent as OlxIcon } from '../../../static/img/olx.svg';
import { ReactComponent as MetaIcon } from '../../../static/img/meta-icon.svg';
import Countdown from 'antd/lib/statistic/Countdown';
import AppContext from '../../../context';
import { isValidPhoneNumber } from '../../../utility/render-photo-number';

type BuscaAvancadaInputs = {
  email: string;
  telefone: string;
  data_cadastro_min: undefined | Moment | undefined;
  data_cadastro_max: undefined | Moment | undefined;
  bant: undefined | string;
  etapa: number | undefined;
  transacao: undefined | string;
  imovel_origem: undefined | string;
  corretor_responsavel: undefined | string;
  interesse: undefined | string;
  etiquetas: string[];
};

type TabelaDeClientesProps = {
  context: any;
  CountLoading: boolean;
  permissões?: PermissoesInterface;
  rowSelection: any;
  clientes: Cliente[];
  setClientes: React.Dispatch<React.SetStateAction<any[]>>;
  TableReset: boolean;
  setTableReset: any;
  SearchText: any;
  showEditModal: any;
  onHandleDelete: any;
  onHandleRevive: any;
  onHandleFish: any;
  BuscaAvancada: BuscaAvancadaInputs;
  FindProprietarios: boolean;
  adicionarModalCliente: any;
  current: number;
  pageSize: number;
  clientesTotal: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setBuscar: React.Dispatch<React.SetStateAction<boolean>>;
  handleStatusChange: (cliente: Cliente, status: 'ganho' | 'perdido', reason?: string) => void;
  columnOrder: { [key: string]: 'ascend' | 'descend' | undefined };
  setColumnOrder: React.Dispatch<React.SetStateAction<{ [key: string]: 'ascend' | 'descend' | undefined }>>;
  openModalVisualizarCorretor: Function;
  bolsao: boolean;
};

interface TagRecenteProps {
  alt?: boolean;
  home?: boolean;
}

const TagRecente = styled.div<TagRecenteProps>`
  margin-right: 4px !important;
  align-items: center;
  justify-content: center;
  background-color: #e5e5ff;
  ${props =>
    props.alt
      ? `
        background-color: #ffe7ba;
        svg {
          color: #d46b08
        }
      `
      : ''}
  ${props =>
    props.home
      ? `
        background-color: #e6fffb;
        svg {
          color: #08979c
        }
      `
      : ''}
  display: block !important;
  float: left;
  width: unset;
  border-radius: 4px;
  padding: 3px 4px 0px !important;
`;

const color_etapas: { [etapa: string]: string } = {
  '0': '#5f63f2',
  '1': '#f759ab',
  '2': '#9254de',
  '3': '#597ef7',
  '4': '#d4b106',
  '5': '#389e0d',
  '6': '#13c2c2',
  '7': '#141414',
  '9999': '#4b4b4b',
};

const bg_color_etapas: { [etapa: string]: string } = {
  '0': '#e5e5ff',
  '1': '#ffd6e7',
  '2': '#efdbff',
  '3': '#d6e4ff',
  '4': '#fff1b8',
  '5': '#d9f7be',
  '6': '#b5f5ec',
  '7': '#595959',
  '9999': '#c5c5c5',
};

const TagEtapa = styled(TagRecente)<{ etapa?: number }>`
  padding: 1px 5px 2px !important;
  color: ${props => color_etapas[String(props.etapa)] || '#5f63f2'};
  background-color: ${props => bg_color_etapas[String(props.etapa)] || '#e5e5ff'};
`;

function truncate(str: string, n: number) {
  if (!str) return '';
  return str.length > n ? str.substring(0, n - 1) + '…' : str;
}

export const TabelaDeClientes = (props: TabelaDeClientesProps) => {
  const {
    rowSelection,
    clientes,
    setClientes,
    CountLoading,
    showEditModal,
    onHandleDelete,
    onHandleRevive,
    onHandleFish,
    adicionarModalCliente,
    clientesTotal,
    pageSize,
    current,
    setCurrent,
    setPageSize,
    setBuscar,
    columnOrder,
    setColumnOrder,
    openModalVisualizarCorretor,
    bolsao,
    handleStatusChange,
  } = props;
  const context = useContext(AppContext);

  const [checklistModalVisible, setChecklistModalVisible] = useState(false);
  const [currentChecklistClient, setCurrentChecklistClient] = useState<Cliente | null>(null);

  const [isTempoLimiteAtivo, setIsTempoLimiteAtivo] = useState<boolean>(false);
  const [tipoDeTempo, setTipoDeTempo] = useState<string>('dia');
  const [tempoLimite, setTempoLimite] = useState<number>(1);
  const [acaoPendencia, setAcaoPendencia] = useState<string>('encaminhar_usuario');
  const [corretorId, setCorretorId] = useState<string | undefined>(undefined);
  const [deadline, setDeadline] = useState<number | null>(null);
  const [mostrarTempo, setMostrarTempo] = useState<boolean>(false);

  const isChecklistComplete =
    currentChecklistClient?.checklist_feito?.length === (currentChecklistClient?.etapa_funil?.checklist?.length || 0);

  const isDeadlinePassed = deadline ? deadline < Date.now() : false;

  const disableCheckboxes = isChecklistComplete || isDeadlinePassed;

  const openChecklistModal = (cliente: Cliente) => {
    setCurrentChecklistClient(cliente);
    setChecklistModalVisible(true);
  };

  const onUpdateClienteChecklist = async (clienteId: string, updatedChecklistFeito: string[]) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/clientes/${clienteId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ checklist_feito: updatedChecklistFeito, edited_by_id: context.user?.db_id }),
      });

      if (!response.ok) {
        throw new Error('Erro ao atualizar o cliente no backend');
      }
      const updatedCliente = await response.json();

      setClientes(prevClientes =>
        prevClientes.map(cliente =>
          cliente.db_id === clienteId
            ? {
                ...cliente,
                checklist_feito: updatedChecklistFeito,
                etapa_funil: updatedCliente.etapa_funil ? updatedCliente.etapa_funil : cliente.etapa_funil,
              }
            : cliente,
        ),
      );

      if (currentChecklistClient?.db_id === clienteId) {
        setCurrentChecklistClient(prevClient =>
          prevClient ? { ...prevClient, checklist_feito: updatedChecklistFeito } : null,
        );
      }

      message.success('Checklist atualizado com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar o cliente:', error);
      message.error('Erro ao atualizar o checklist!');
    }
  };

  const handleChecklistItemToggle = (itemName: string) => {
    if (!currentChecklistClient) return;

    if (disableCheckboxes) return;

    const currentChecklistFeito = currentChecklistClient.checklist_feito || [];
    let updatedChecklistFeito: string[];

    if (currentChecklistFeito.includes(itemName)) {
      updatedChecklistFeito = currentChecklistFeito.filter(name => name !== itemName);
    } else {
      updatedChecklistFeito = [...currentChecklistFeito, itemName];
    }

    onUpdateClienteChecklist(currentChecklistClient.db_id, updatedChecklistFeito);
  };

  useEffect(() => {
    if (
      currentChecklistClient?.etapa_funil?.tempo_para_realizar_checklist &&
      currentChecklistClient.etapa_funil.tipo_de_tempo
    ) {
      setIsTempoLimiteAtivo(true);
      setTipoDeTempo(currentChecklistClient.etapa_funil.tipo_de_tempo);
      setTempoLimite(currentChecklistClient.etapa_funil.tempo_para_realizar_checklist);
    } else {
      setIsTempoLimiteAtivo(false);
    }

    if (currentChecklistClient?.etapa_funil?.acao_pendencia) {
      setAcaoPendencia(currentChecklistClient.etapa_funil.acao_pendencia);
      if (currentChecklistClient.etapa_funil.acao_pendencia === 'encaminhar_usuario') {
        setCorretorId(currentChecklistClient.corretor_responsavel_id);
      } else {
        setCorretorId(undefined);
      }
    } else {
      setAcaoPendencia('');
      setCorretorId(undefined);
    }

    const calcularDeadline = () => {
      if (!currentChecklistClient?.data_entry_funil || !isTempoLimiteAtivo) return null;
      let tempoEmMs = 0;
      switch (tipoDeTempo) {
        case 'minuto':
          tempoEmMs = tempoLimite * 60 * 1000;
          break;
        case 'hora':
          tempoEmMs = tempoLimite * 60 * 60 * 1000;
          break;
        case 'dia':
        default:
          tempoEmMs = tempoLimite * 24 * 60 * 60 * 1000;
          break;
      }
      const startTime = new Date(currentChecklistClient.data_entry_funil).getTime();
      return startTime + tempoEmMs;
    };

    const computedDeadline = calcularDeadline();
    setDeadline(computedDeadline);

    const todosItensFeitos =
      currentChecklistClient?.checklist_feito?.length === (currentChecklistClient?.etapa_funil?.checklist?.length || 0);

    const todosItensObrigatoriosFeitos =
      currentChecklistClient?.etapa_funil?.checklist
        ?.filter(item => item.required)
        .every(item => currentChecklistClient.checklist_feito?.includes(item.name)) || false;

    const shouldMostrarTempo = isTempoLimiteAtivo && !todosItensFeitos && !todosItensObrigatoriosFeitos;

    setMostrarTempo(shouldMostrarTempo);
  }, [currentChecklistClient, isTempoLimiteAtivo, tipoDeTempo, tempoLimite]);

  const getDeadlineColor = (cliente: Cliente) => {
    const totalTasks = cliente.etapa_funil?.checklist?.length || 0;
    const completedTasks = cliente.checklist_feito?.length || 0;

    if (completedTasks >= totalTasks) {
      return 'green'; // Todas as tarefas concluídas
    }

    if (
      !cliente.data_entry_funil ||
      !cliente.etapa_funil?.tempo_para_realizar_checklist ||
      !cliente.etapa_funil.tipo_de_tempo
    )
      return 'inherit';

    const tempoLimite = cliente.etapa_funil.tempo_para_realizar_checklist;
    const tipoDeTempo = cliente.etapa_funil.tipo_de_tempo;

    let tempoEmMs = 0;
    switch (tipoDeTempo) {
      case 'minuto':
        tempoEmMs = tempoLimite * 60 * 1000;
        break;
      case 'hora':
        tempoEmMs = tempoLimite * 60 * 60 * 1000;
        break;
      case 'dia':
      default:
        tempoEmMs = tempoLimite * 24 * 60 * 60 * 1000;
        break;
    }

    const startTime = new Date(cliente.data_entry_funil).getTime();
    const deadlineTime = startTime + tempoEmMs;

    const remainingTime = deadlineTime - Date.now();

    if (remainingTime <= 0) {
      return 'gray'; // Prazo expirado
    }

    const totalTime = tempoEmMs;
    const percentage = remainingTime / totalTime;

    if (percentage > 0.66) {
      return 'green';
    } else if (percentage > 0.33) {
      return 'orange';
    } else {
      return 'red';
    }
  };

  const formatRemainingTime = (cliente: Cliente) => {
    if (
      !cliente.data_entry_funil ||
      !cliente.etapa_funil?.tempo_para_realizar_checklist ||
      !cliente.etapa_funil.tipo_de_tempo
    )
      return '';

    const tempoLimite = cliente.etapa_funil.tempo_para_realizar_checklist;
    const tipoDeTempo = cliente.etapa_funil.tipo_de_tempo;

    let tempoEmMs = 0;
    switch (tipoDeTempo) {
      case 'minuto':
        tempoEmMs = tempoLimite * 60 * 1000;
        break;
      case 'hora':
        tempoEmMs = tempoLimite * 60 * 60 * 1000;
        break;
      case 'dia':
      default:
        tempoEmMs = tempoLimite * 24 * 60 * 60 * 1000;
        break;
    }

    const startTime = new Date(cliente.data_entry_funil).getTime();
    const deadlineTime = startTime + tempoEmMs;

    const remainingTime = deadlineTime - Date.now();

    if (remainingTime <= 0) {
      return 'Tempo esgotado';
    }

    const duration = moment.duration(remainingTime);
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    let parts: string[] = [];
    if (days > 0) parts.push(`${days}d`);
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);
    if (seconds > 0) parts.push(`${seconds}s`);

    return parts.join(' ');
  };

  const calcularPercentual = (feito: number, total: number) => {
    if (total === 0) return 0;
    return Math.round((feito / total) * 100);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    const { columnKey, order } = sorter;

    setColumnOrder({
      [columnKey]: order,
    });
    setBuscar(true);
  };

  const columns: any = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
      sortOrder: columnOrder['nome'],
      sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
      render: (text, cliente) => {
        const Difference_In_Days =
          cliente.created_at && cliente.created_at.getTime
            ? (Date.now() - cliente.created_at.getTime()) / (1000 * 3600 * 24)
            : 10;

        return (
          <a onClick={() => adicionarModalCliente(cliente)} title={cliente.nome}>
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              {cliente.proprietario === true && (
                <Popover title="Este cliente é um proprietário!">
                  <TagRecente home>
                    <FeatherIcon icon="home" size={14} />
                  </TagRecente>
                </Popover>
              )}
              {Difference_In_Days < 2 && (
                <Popover title="Cliente cadastrado recentemente!">
                  <TagRecente>
                    <FeatherIcon icon="zap" size={14} />
                  </TagRecente>
                </Popover>
              )}
              {cliente.recadastro && (
                <Popover
                  title="Cliente recadastrado!"
                  content={
                    <p style={{ maxWidth: 200, marginTop: 10 }}>
                      Isso ocorre quando o lead se cadastra pelo site utilizando um email e um telefone de um cliente já
                      inserido no banco.
                    </p>
                  }
                >
                  <TagRecente alt>
                    <FeatherIcon icon="corner-up-right" size={14} />
                  </TagRecente>
                </Popover>
              )}
              <PopoverConjuge cliente={cliente} />
              <Popover
                content={
                  <>
                    <div>
                      {cliente.origem_site
                        ? 'Site'
                        : cliente.lead_grupo_zap
                        ? 'Grupo OLX'
                        : cliente.formulario_id
                        ? 'Campanha'
                        : 'Manual'}
                    </div>
                    {(cliente.origem_site || cliente.formulario_id) && (
                      <div style={{ marginTop: 10 }}>
                        <strong>{cliente.origem_site ? 'Informações de UTM:' : 'Informações de Cadastro:'}</strong>
                        {cliente.origem_site && (
                          <>
                            <div>
                              <strong>Origem:</strong> {cliente.utm_source || 'Não disponível'}
                            </div>
                            <div>
                              <strong>Meio:</strong> {cliente.utm_medium || 'Não disponível'}
                            </div>
                            <div>
                              <strong>Campanha:</strong> {cliente.utm_campaign || 'Não disponível'}
                            </div>
                            <div>
                              <strong>Conteúdo:</strong> {cliente.utm_content || 'Não disponível'}
                            </div>
                            <div>
                              <strong>Termo:</strong> {cliente.utm_term || 'Não disponível'}
                            </div>
                          </>
                        )}
                        {cliente.formulario_id && !cliente.origem_site && (
                          <>
                            <div>
                              <strong>Nome do Formulário:</strong> {cliente.formulario?.name || 'Não disponível'}
                            </div>
                            <div>
                              <strong>Canal de Origem:</strong> Meta
                            </div>
                            <div>
                              <strong>Nome do Anúncio:</strong> {cliente.utm_campaign || 'Não disponível'}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </>
                }
                title="Origem do Cliente"
                trigger="hover"
              >
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  {cliente.origem_site && (
                    <TagRecente home>
                      <FeatherIcon icon="globe" size={14} style={{ color: '#1890ff' }} />
                    </TagRecente>
                  )}
                  {cliente.lead_grupo_zap && (
                    <TagRecente
                      alt
                      style={{
                        backgroundColor: 'rgba(128, 0, 128, 0.2)',
                      }}
                    >
                      <OlxIcon style={{ width: 14, height: 14 }} />
                    </TagRecente>
                  )}
                  {cliente.formulario_id && (
                    <TagRecente>
                      <MetaIcon style={{ width: 14, height: 14 }} />
                    </TagRecente>
                  )}
                </div>
              </Popover>
              <span style={{ marginLeft: 8 }}>{truncate(text, 20)}</span>
            </div>
          </a>
        );
      },
    },
    {
      title: 'Imóvel de origem',
      dataIndex: 'imovel_origem',
      key: 'imovel_origem',
      sorter: (a: any, b: any) => (a.imovel_origem?.codigo || '').localeCompare(b.imovel_origem?.codigo),
      sortOrder: columnOrder['imovel_origem'],
      render: (imovel_origem, cliente) => <AvatarImovel imovel_id={imovel_origem?.db_id} cliente={cliente} />,
    },
  ];

  if (!bolsao) {
    columns.push(
      {
        title: 'Telefone',
        dataIndex: 'telefone',
        key: 'telefone',
        sorter: (a: any, b: any) => (a.telefone || '').localeCompare(b.telefone),
        sortOrder: columnOrder['telefone'],
        render: (_, cliente) => {
          const hasDDD = !!cliente.DDD;
          const hasValidPhone = isValidPhoneNumber(cliente.telefone || '');
          const phoneNumber = `55${cliente.DDD || ''}${cliente.telefone ? cliente.telefone.replace(/[-()]/g, '') : ''}`;
          const displayNumber = `${cliente.DDD || ''} ${cliente.telefone || ''}`.trimLeft();

          return hasDDD || hasValidPhone ? (
            <a
              style={{ display: 'flex', alignItems: 'center' }}
              target="_blank"
              rel="noopener noreferrer"
              href={`https://api.whatsapp.com/send?phone=${phoneNumber}`}
            >
              <WhatsIcon />
              {displayNumber}
            </a>
          ) : (
            displayNumber || <span style={{ opacity: 0.5 }}>Nenhum definido</span>
          );
        },
      },
      {
        title: 'Etapa',
        dataIndex: 'etapa_funil',
        key: 'etapa_funil',
        sorter: (a: any, b: any) => (a.etapa_funil?.title || '').localeCompare(b.etapa_funil?.title),
        sortOrder: columnOrder['etapa_funil'],
        render: (etapa, cliente) => {
          let clockColor = getDeadlineColor(cliente);
  
          if (isChecklistComplete) {
            clockColor = 'green';
          }
  
          if (isDeadlinePassed && !isChecklistComplete) {
            clockColor = 'gray';
          }
  
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TagEtapa etapa={cliente.etapa_funil?.ordem || 0}>{cliente.etapa_funil?.title || 'Indef.'}</TagEtapa>
              {cliente.etapa_funil?.checklist && cliente.etapa_funil.checklist.length > 0 && (
                <Button
                  type="link"
                  icon={<CheckSquareOutlined />}
                  onClick={() => openChecklistModal(cliente)}
                  style={{ marginLeft: 8 }}
                />
              )}
              {cliente.etapa_funil?.tempo_para_realizar_checklist && (
                <Tooltip
                  title={
                    isChecklistComplete
                      ? 'Checklist concluído.'
                      : isDeadlinePassed
                      ? 'Prazo expirado.'
                      : `Tempo restante: ${formatRemainingTime(cliente)}`
                  }
                >
                  <ClockCircleOutlined
                    style={{
                      marginLeft: 8,
                      color: clockColor,
                      fontSize: '16px',
                    }}
                  />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        title: 'BANT',
        dataIndex: 'bant',
        key: 'bant',
        sorter: (a: any, b: any) => (a.bant || '').localeCompare(b.bant || ''),
        sortOrder: columnOrder['bant'],
      },
    );
  }

  columns.push(
    {
      title: 'Interesses',
      dataIndex: 'interesses',
      key: 'interesses',
      render: (interesses, cliente) =>
        cliente.interesse_venda
          ? 'Venda'
          : cliente.interesse_locacao
          ? 'Locação'
          : cliente.interesse_locacao_temporada
          ? 'Temporada'
          : cliente.imovel_origem?.venda
          ? 'Venda'
          : cliente.imovel_origem?.locação
          ? 'Locação'
          : cliente.imovel_origem?.temporada
          ? 'Temporada'
          : '',
    },
    {
      title: 'Datas',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a: any, b: any) => {
        const dateA = new Date(a.created_at).getTime();
        const dateB = new Date(b.created_at).getTime();
        return dateA - dateB;
      },
      sortOrder: columnOrder['created_at'],
      render: (_, cliente) => (
        <>
          <div>
            Cadastro: {cliente.created_at ? new Date(cliente.created_at).toLocaleDateString() : 'Não registrado'}
          </div>
          <div>Edição: {cliente.edited_at ? new Date(cliente.edited_at).toLocaleDateString() : 'Não registrado'}</div>
        </>
      ),
    },
  );

  if (!bolsao) {
    columns.push({
      title: 'Corretor Responsável',
      dataIndex: 'corretor_responsavel',
      key: 'corretor_responsavel',
      sorter: (a: any, b: any) => (a.corretor_responsavel?.nome || '').localeCompare(b.corretor_responsavel?.nome),
      sortOrder: columnOrder['corretor_responsavel'],
      render: (_, cliente) =>
        cliente.corretor_responsavel ? (
          <Typography.Link onClick={() => openModalVisualizarCorretor(cliente.corretor_responsavel)}>
            {cliente.corretor_responsavel?.nome || cliente.corretor_responsavel?.email}
          </Typography.Link>
        ) : (
          <span style={{ opacity: 0.5 }}>Nenhum definido</span>
        ),
    });
  }

  columns.push({
    title: 'Ações',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
    render: (_, cliente) => (
      <div className="table-actions">
        {!bolsao ? (
          <>
            <Button
              className="btn-icon"
              type="info"
              icon={<LikeOutlined />}
              onClick={() => props.handleStatusChange(cliente, 'ganho')}
              style={{
                borderRadius: '50%',
                padding: '4px',
                backgroundColor: (cliente.status || '').toLowerCase() === 'ganho' ? '#f0f0f0' : undefined,
                color: (cliente.status || '').toLowerCase() === 'ganho' ? 'green' : '#ADB4D2',
              }}
              size="small"
            />
            <Button
              className="btn-icon"
              type="info"
              icon={<DislikeOutlined />}
              onClick={() => props.handleStatusChange(cliente, 'perdido')}
              style={{
                borderRadius: '50%',
                padding: '4px',
                backgroundColor: (cliente.status || '').toLowerCase() === 'perdido' ? '#f0f0f0' : undefined,
                color: (cliente.status || '').toLowerCase() === 'perdido' ? 'red' : '#ADB4D2',
              }}
              size="small"
            />
            <Button className="btn-icon" type="info" onClick={() => showEditModal(cliente, true)} shape="circle">
              <FeatherIcon icon="user" size={14} />
            </Button>
            <Button className="btn-icon" type="info" onClick={() => showEditModal(cliente)} shape="circle">
              <FeatherIcon icon="edit" size={14} />
            </Button>
            {(props.permissões?.lead_excluir || props.permissões?.alterar_cadastro) &&
              (!(cliente.excluido || cliente.deleted_at) ? (
                <Popconfirm
                  placement="topRight"
                  title="Tem certeza que quer excluir esse cliente?"
                  onConfirm={() => onHandleDelete(cliente.db_id)}
                  okText="Sim, excluir"
                  cancelText="Não"
                >
                  <Button className="btn-icon" type="danger" shape="circle">
                    <FeatherIcon icon="trash-2" size={14} />
                  </Button>
                </Popconfirm>
              ) : (
                <Popconfirm
                  placement="topRight"
                  title="Você quer restaurar esse cliente?"
                  onConfirm={() => onHandleRevive(cliente.db_id)}
                  okText="Sim"
                  cancelText="Não"
                >
                  <Button className="btn-icon" type="info" shape="circle">
                    <FeatherIcon icon="skip-back" size={14} />
                  </Button>
                </Popconfirm>
              ))}
          </>
        ) : (
          <Popconfirm
            placement="topRight"
            title="Tem certeza que quer capturar esse cliente?"
            onConfirm={() => onHandleFish(cliente.db_id)}
            okText="Sim"
            cancelText="Não"
          >
            <Button className="btn-icon" type="info" shape="circle">
              <FeatherIcon icon="skip-back" size={14} />
            </Button>
          </Popconfirm>
        )}
      </div>
    ),
  });

  return (
    <UserTableStyleWrapper>
      <div className="contact-table">
        <TableWrapper className="table-responsive" style={{ position: 'relative' }}>
          <Table
            locale={{
              emptyText: (
                <Empty
                  image={require('../../../static/img/empty.svg')}
                  imageStyle={{
                    height: 60,
                  }}
                  description={
                    CountLoading ? (
                      <p>
                        Nenhum cliente encontrado com esse filtro até agora! <br />
                        Espere pelo fim do{' '}
                        <Popover title="Escaneamento de leads em andamento" trigger={['hover']}>
                          <b style={{ color: '#5F63F2' }}>escaneamento!</b>
                        </Popover>
                      </p>
                    ) : (
                      <span>Nenhum cliente encontrado com esse filtro!</span>
                    )
                  }
                />
              ),
            }}
            rowSelection={rowSelection}
            dataSource={clientes}
            columns={columns}
            onChange={handleTableChange}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100', '500', '1000'],
              total: clientesTotal,
              current,
              pageSize,
              onShowSizeChange: (current, size) => {
                setPageSize(size);
                setBuscar(true);
              },
              onChange: page => {
                setCurrent(page);
                setBuscar(true);
              },
              showTotal:
                window.screen.width > 600
                  ? (total, range) => {
                      return (
                        <>
                          <div className="search-result" style={CountLoading ? { opacity: 0.7 } : undefined}>
                            <CountNClientes
                              CountLoading={CountLoading}
                              FilteredClientes={clientes}
                              nClientes={clientesTotal}
                              total={total}
                              range={range}
                            />
                            {CountLoading && (
                              <Popover
                                trigger={['hover']}
                                title="O que isso significa?"
                                content={
                                  <p style={{ marginTop: 8, maxWidth: 200 }}>
                                    Estamos escaneando o seu cache e retornando os clientes na medida em que eles são
                                    encontrados.
                                  </p>
                                }
                              >
                                <FeatherIcon size={18} icon="loader" />
                              </Popover>
                            )}
                          </div>
                        </>
                      );
                    }
                  : undefined,
              ...antdTableLocale,
            }}
          />
        </TableWrapper>
      </div>
      <Modal
        visible={checklistModalVisible}
        title={`Check-list - ${currentChecklistClient?.nome}`}
        onCancel={() => setChecklistModalVisible(false)}
        footer={null}
        width={700}
      >
        {currentChecklistClient && (
          <div>
            <Typography.Text>
              {`${currentChecklistClient.checklist_feito?.length || 0} de ${currentChecklistClient.etapa_funil
                ?.checklist?.length || 0} tarefas concluídas`}
            </Typography.Text>
            <Progress
              percent={calcularPercentual(
                currentChecklistClient.checklist_feito?.length || 0,
                currentChecklistClient.etapa_funil?.checklist?.length || 0,
              )}
              status={
                calcularPercentual(
                  currentChecklistClient.checklist_feito?.length || 0,
                  currentChecklistClient.etapa_funil?.checklist?.length || 0,
                ) === 100
                  ? 'success'
                  : 'active'
              }
              style={{ marginBottom: 16 }}
            />
            {currentChecklistClient.etapa_funil?.checklist?.map(item => (
              <div key={item.name} style={{ marginBottom: 8 }}>
                <Checkbox
                  checked={currentChecklistClient.checklist_feito?.includes(item.name) || false}
                  onChange={() => handleChecklistItemToggle(item.name)}
                  disabled={disableCheckboxes}
                >
                  {item.name}
                  {item.required && (
                    <Tag color="red" style={{ marginLeft: 8 }}>
                      Obrigatório
                    </Tag>
                  )}
                </Checkbox>
              </div>
            ))}
            {mostrarTempo && deadline && (
              <>
                <Divider plain>Tempo Restante</Divider>
                <div style={{ marginTop: 24, textAlign: 'center' }}>
                  <Progress
                    type="circle"
                    percent={calcularPercentual(
                      currentChecklistClient.checklist_feito?.length || 0,
                      currentChecklistClient.etapa_funil?.checklist?.length || 0,
                    )}
                    format={() => {
                      const remainingTime = deadline - Date.now();
                      if (remainingTime <= 0) return 'Tempo Esgotado';
                      return (
                        <Countdown
                          value={deadline}
                          format="HH:mm:ss"
                          onFinish={() => {
                            message.info('O tempo para concluir o checklist expirou.');
                          }}
                        />
                      );
                    }}
                    status={
                      calcularPercentual(
                        currentChecklistClient.checklist_feito?.length || 0,
                        currentChecklistClient.etapa_funil?.checklist?.length || 0,
                      ) === 100
                        ? 'success'
                        : 'active'
                    }
                  />
                </div>
              </>
            )}

            {isChecklistComplete && (
              <Typography.Text type="success" style={{ marginTop: 16, display: 'block' }}>
                Todas as tarefas foram concluídas.
              </Typography.Text>
            )}
            {isDeadlinePassed && !isChecklistComplete && (
              <Typography.Text type="danger" style={{ marginTop: 16, display: 'block' }}>
                O prazo para concluir o checklist expirou.
              </Typography.Text>
            )}
          </div>
        )}
      </Modal>
    </UserTableStyleWrapper>
  );
};

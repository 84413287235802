import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Checkbox, message, InputNumber, DatePicker } from 'antd';
import { BasicFormWrapper } from '../styled';
import { Button } from '../../../components/buttons/buttons';
import { AddUser } from '../../pages/style';
import { Modal } from '../../../components/modals/antd-modals';
import FiltrarAgenciador from '../../../components/filtros-smart/usuarios';
import FiltrarClientes from '../../../components/filtros-smart/clientes';
import styled from 'styled-components';
import AppContext from '../../../context';
import ButtonGroup from 'antd/lib/button/button-group';
import axios from 'axios';
import moment from 'moment';
import { Imóvel } from 'smart-imob-types';
import FiltrarImoveis from '../../../components/filtros-smart/imoveis';

interface RegistrarVendaModalProps {
  visible: boolean;
  onCancel: () => void;
  imovel_id?: string;
  clienteId?: string;
  onSubmit?: (db_id: string, updatedImovel: any) => void;
  etapaFunilId?: string | null;
}

export const currencyFormatter = (value?: any): any => (value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '');

export const currencyParser = (value?: any): any => (value ? value.replace(/R\$\s?|(\.*)/g, '').replace(',', '.') : '');

const RegistrarVendaModal = ({
  visible,
  onCancel,
  imovel_id,
  clienteId,
  onSubmit,
  etapaFunilId,
}: RegistrarVendaModalProps) => {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [vendedor, setVendedor] = useState<any>(null);
  const [comprador, setComprador] = useState<any>(null);
  const [ImovelObj, setImovelObj] = useState<Imóvel | null>(null);

  const [editar, setEditar] = useState<boolean>(false);

  const [valorVenda, setValorVenda] = useState<number | null>(null);
  const [porcentagemEmpresa, setPorcentagemEmpresa] = useState<number | null>(null);
  const [porcentagemCorretor, setPorcentagemCorretor] = useState<number | null>(null);
  const [valorEmpresa, setValorEmpresa] = useState<number | null>(null);
  const [valorCorretor, setValorCorretor] = useState<number | null>(null);

  const [podeDividir, setPodeDividir] = useState<boolean>(false);
  const [excluirImovelOnEnd, setExcluirImovelOnEnd] = useState<boolean>(false);
  const [comParticipante, setComParticipante] = useState<boolean>(false);
  const [comParceiro, setComParceiro] = useState<boolean>(false);
  const [dividirComissaoAg, setDividirComissaoAg] = useState(false);

  const handleChangeVendedor = (value: any) => {
    setVendedor(value);
    form.setFieldsValue({ vendedor: value });
  };
  const handleChangeComprador = (value: any) => {
    setComprador(value);
    form.setFieldsValue({ comprador: value });
  };

  const handleOk = async (values: any) => {
    setLoading(true);
    try {
      const imovelId = imovel_id || values.cod_imovel.db_id;
      const comprador_id = clienteId ? clienteId : comprador ? comprador.value : null;

      const createImovelVendaDto: any = {
        porcentagem_corretor: porcentagemCorretor || 0,
        porcentagem_empresa: porcentagemEmpresa || 0,
        valor_venda: valorVenda || 0,
        observacao: values.observacao || '',
        imovel_id: imovelId,
        data: values.data_venda ? moment(values.data_venda).toISOString() : new Date().toISOString(),
        valor_empresa: valorEmpresa || null,
        valor_corretor: valorCorretor || null,
        empresa_id: context.empresa.db_id,
        edited_by_id: context.user?.db_id || null,
        comprador_id,
        vendedor_id: vendedor ? vendedor.value : null,
        venda_com_participante: comParticipante,
        participante_nome: comParticipante ? values.participante_nome : null,
        participante_comissao: comParticipante ? values.participante_comissao : null,
        venda_com_parceiro: comParceiro,
        parceiro_nome: comParceiro ? values.parceiro_nome : null,
        parceiro_comissao: comParceiro ? values.parceiro_comissao : null,
      };

      if (excluirImovelOnEnd) createImovelVendaDto['excluir_imovel'] = true;

      const response = await axios.post(`${process.env.REACT_APP_API_URI}/imovel-vendas`, createImovelVendaDto, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = response.data;
      message.success('Venda registrada com sucesso!');

      if (onCancel) {
        onCancel();
      }

      if (onSubmit) {
        onSubmit(imovelId, data);
      }
    } catch (error: any) {
      message.error('Erro ao registrar venda!');
      console.error(error.response?.data || error.message);
    }
    setLoading(false);
  };

  const vendidoPelaConcorrencia = async () => {
    setLoading(true);
    try {
      const toUpdate = {
        situacao_venda: 'Vendido pela concorrência',
        vendido: true,
        edited_at: new Date().toISOString(),
        edited_by_id: context.user?.db_id || null,
      };
      if (excluirImovelOnEnd) toUpdate['excluido'] = true;

      await axios.patch(`${process.env.REACT_APP_API_URI}/imoveis/${imovel_id}`, toUpdate, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      message.success('Situação de venda alterada para "Vendido pela concorrência".');

      if (onCancel) onCancel();

      if (onSubmit) onSubmit(imovel_id || '', toUpdate);
    } catch (error: any) {
      message.error('Erro ao alterar situação da venda!');
      console.error(error.response?.data || error.message);
    }
    setLoading(false);
  };

  const proprietarioDesistiu = async () => {
    setLoading(true);
    try {
      const toUpdate = {
        situacao_venda: 'Proprietário desistiu',
        vendido: true,
        edited_at: new Date().toISOString(),
        edited_by_id: context.user?.db_id || null,
      };
      if (excluirImovelOnEnd) toUpdate['excluido'] = true;

      await axios.patch(`${process.env.REACT_APP_API_URI}/imoveis/${imovel_id}`, toUpdate, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      message.success('Situação de venda alterada para "Proprietário desistiu".');

      if (onCancel) onCancel();

      if (onSubmit) onSubmit(imovel_id || '', toUpdate);
    } catch (error: any) {
      message.error('Erro ao alterar situação da venda!');
      console.error(error.response?.data || error.message);
    }
    setLoading(false);
  };

  const [form] = Form.useForm();

  const fetchImovel = async (id: string) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URI}/imoveis/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const imovelData = response.data;
      form.setFieldsValue({
        cod_imovel: imovelData,
      });
      setPodeDividir(Boolean(imovelData.agenciador_id));
    } catch (error: any) {
      console.error('Erro ao buscar dados do imóvel:', error.response?.data || error.message);
    }
  };

  useEffect(() => {
    if (imovel_id) {
      const fetchVenda = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URI}/imovel-vendas/imovel/${imovel_id}`, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const imovelData = response.data;
          form.setFieldsValue({
            vendedor: imovelData.vendedor?.db_id || null,
            comprador: imovelData.comprador?.db_id || null,
            valor_venda: imovelData.valor_venda || null,
            data_venda: imovelData.data ? moment(imovelData.data) : null,
            porcentagem_empresa: imovelData.porcentagem_empresa || null,
            porcentagem_corretor: imovelData.porcentagem_corretor || null,
            valor_empresa: imovelData.valor_empresa || null,
            valor_corretor: imovelData.valor_corretor || null,
            observacao: imovelData.observacao || '',
            venda_com_participante: imovelData.venda_com_participante,
            participante_nome: imovelData.participante_nome || '',
            participante_comissao: imovelData.participante_comissao || null,
            venda_com_parceiro: imovelData.venda_com_parceiro,
            parceiro_nome: imovelData.parceiro_nome || '',
            parceiro_comissao: imovelData.parceiro_comissao || null,
          });

          setEditar(!!imovelData);
          setValorVenda(imovelData.valor_venda || null);
          setPorcentagemEmpresa(imovelData.porcentagem_empresa || null);
          setPorcentagemCorretor(imovelData.porcentagem_corretor || null);
          setValorEmpresa(imovelData.valor_empresa || null);
          setValorCorretor(imovelData.valor_corretor || null);

          setVendedor(
            imovelData.vendedor ? { value: imovelData.vendedor?.db_id, label: imovelData.vendedor?.nome } : null,
          );
          setComprador(
            imovelData.comprador ? { value: imovelData.comprador?.db_id, label: imovelData.comprador?.nome } : null,
          );

          setComParticipante(imovelData.venda_com_participante);
          setComParceiro(imovelData.venda_com_parceiro);
        } catch (error: any) {
          console.error('Erro ao buscar dados do imóvel:', error.response?.data || error.message);
        } finally {
          setLoading(false);
        }
      };
      fetchVenda();
      fetchImovel(imovel_id);
    }
  }, [imovel_id]);

  const handleChangeImovel = async (val: any) => {
    setImovelObj(val);

    if (val?.value) {
      fetchImovel(val?.value);
    }
  };

  const parseNumber = (value: string | number | undefined): number | null => {
    if (typeof value === 'number') return value;
    if (typeof value === 'string') {
      const parsed = parseFloat(value.replace(',', '.'));
      return isNaN(parsed) ? null : parsed;
    }
    return null;
  };

  const handleValorVendaChange = (value: string | number | undefined) => {
    const parsedValorVenda = parseNumber(value);
    setValorVenda(parsedValorVenda);
    if (parsedValorVenda !== null) {
      if (porcentagemEmpresa !== null) {
        const calculatedEmpresa = (porcentagemEmpresa / 100) * parsedValorVenda;
        setValorEmpresa(!isNaN(calculatedEmpresa) ? calculatedEmpresa : null);
        form.setFieldsValue({ valor_empresa: calculatedEmpresa.toFixed(2) });
      } else {
        setValorEmpresa(null);
        form.setFieldsValue({ valor_empresa: null });
      }

      if (porcentagemCorretor !== null) {
        const calculatedCorretor = (porcentagemCorretor / 100) * parsedValorVenda;
        setValorCorretor(!isNaN(calculatedCorretor) ? calculatedCorretor : null);
        form.setFieldsValue({ valor_corretor: calculatedCorretor.toFixed(2) });
      } else {
        setValorCorretor(null);
        form.setFieldsValue({ valor_corretor: null });
      }
    } else {
      setValorEmpresa(null);
      setValorCorretor(null);
    }
  };

  const handlePorcentagemEmpresaChange = (value: string | number | undefined) => {
    const parsedPorcentagemEmpresa = parseNumber(value);
    setPorcentagemEmpresa(parsedPorcentagemEmpresa);
    if (parsedPorcentagemEmpresa !== null && valorVenda !== null) {
      const calculatedEmpresa = (parsedPorcentagemEmpresa / 100) * valorVenda;
      setValorEmpresa(!isNaN(calculatedEmpresa) ? calculatedEmpresa : null);
      form.setFieldsValue({ valor_empresa: calculatedEmpresa.toFixed(2) });
    } else {
      setValorEmpresa(null);
      form.setFieldsValue({ valor_empresa: null });
    }
  };

  const handlePorcentagemCorretorChange = (value: string | number | undefined) => {
    const parsedPorcentagemCorretor = parseNumber(value);
    setPorcentagemCorretor(parsedPorcentagemCorretor);
    if (parsedPorcentagemCorretor !== null && valorVenda !== null) {
      const calculatedCorretor = (parsedPorcentagemCorretor / 100) * valorVenda;
      setValorCorretor(!isNaN(calculatedCorretor) ? calculatedCorretor : null);
      form.setFieldsValue({ valor_corretor: calculatedCorretor.toFixed(2) });
    } else {
      setValorCorretor(null);
      form.setFieldsValue({ valor_corretor: null });
    }
  };

  return (
    <Modal
      type={'primary'}
      title={editar ? 'Editar venda' : 'Registrar venda'}
      visible={visible}
      footer={null}
      onCancel={onCancel}
    >
      <FixStyles className="project-modal">
        <AddUser>
          <BasicFormWrapper>
            <Form form={form} name="registrar_venda" onFinish={handleOk} layout="vertical">
              {!imovel_id && (
                <Form.Item name="cod_imovel" initialValue="" label="Código Imóvel:">
                  <FiltrarImoveis
                    placeholder={'Código do Imóvel'}
                    style={{ height: '100%' }}
                    imovel_obj={ImovelObj}
                    handleChangeImovel={handleChangeImovel}
                    empresa_id={context.empresa.db_id}
                  />
                </Form.Item>
              )}
              <Form.Item
                name="vendedor"
                label="Quem efetuou a venda"
                rules={[{ required: true, message: 'Selecione o vendedor!' }]}
              >
                <FiltrarAgenciador
                  placeholder="Selecione o vendedor"
                  style={{ width: '100%' }}
                  agenciador_obj={vendedor}
                  handleChangeAgenciador={handleChangeVendedor}
                />
              </Form.Item>

              {!clienteId && (
                <Form.Item
                  name="comprador"
                  label="Comprador"
                  rules={[{ required: true, message: 'Selecione o comprador!' }]}
                >
                  <FiltrarClientes
                    style={{ width: '100%' }}
                    cliente_obj={comprador}
                    handleChangeCliente={handleChangeComprador}
                    empresa_id={context.empresa?.db_id}
                  />
                </Form.Item>
              )}

              <Form.Item
                name="valor_venda"
                label="Valor da venda"
                rules={[{ required: true, message: 'Insira o valor da venda!' }]}
              >
                <InputNumber
                  placeholder="Valor da venda"
                  formatter={currencyFormatter}
                  parser={currencyParser}
                  style={{ width: '100%' }}
                  value={valorVenda ?? undefined}
                  onChange={handleValorVendaChange}
                />
              </Form.Item>

              <Form.Item
                name="data_venda"
                label="Data da Venda"
                rules={[{ required: true, message: 'Selecione a data da venda!' }]}
              >
                <DatePicker format="DD/MM/YYYY" placeholder="Selecione a data de venda" style={{ width: '100%' }} />
              </Form.Item>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Form.Item name="porcentagem_empresa" label="Comissão da Imobiliária (%)" style={{ width: '48%' }}>
                  <InputNumber
                    step={0.1}
                    min={0}
                    max={100}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    placeholder="Porcentagem de comissão da imobiliária"
                    style={{ width: '100%' }}
                    value={porcentagemEmpresa ?? undefined}
                    onChange={handlePorcentagemEmpresaChange}
                  />
                </Form.Item>

                <Form.Item name="porcentagem_corretor" label="Comissão do Corretor (%)" style={{ width: '48%' }}>
                  <InputNumber
                    step={0.1}
                    min={0}
                    max={100}
                    disabled={!podeDividir}
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    placeholder="Porcentagem de comissão do corretor"
                    style={{ width: '100%' }}
                    value={porcentagemCorretor ?? undefined}
                    onChange={handlePorcentagemCorretorChange}
                  />
                </Form.Item>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                <Form.Item name="valor_empresa" label="Valor para a imobiliária" style={{ width: '48%' }}>
                  <InputNumber
                    placeholder="Valor para a imobiliária"
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    style={{ width: '100%' }}
                    value={valorEmpresa ?? undefined}
                    disabled
                    readOnly
                  />
                </Form.Item>

                <Form.Item name="valor_corretor" label="Valor para o corretor" style={{ width: '48%' }}>
                  <InputNumber
                    placeholder="Valor para o corretor"
                    formatter={currencyFormatter}
                    parser={currencyParser}
                    style={{ width: '100%' }}
                    value={valorCorretor ?? undefined}
                    disabled
                    readOnly
                  />
                </Form.Item>
              </div>

              <Form.Item name="observacao" label="Observações">
                <Input.TextArea placeholder="Observações" rows={4} />
              </Form.Item>

              {!editar && (
                <Form.Item name="excluir_imovel" valuePropName="checked">
                  <Checkbox onChange={e => setExcluirImovelOnEnd(e.target.checked)}>
                    Excluir o imóvel ao finalizar a venda?
                  </Checkbox>
                </Form.Item>
              )}

              <div style={{ marginBottom: 10 }}>
                <Checkbox
                  checked={comParticipante}
                  disabled={podeDividir || comParceiro}
                  onChange={() => setComParticipante(!comParticipante)}
                >
                  Incluir Participante na Venda
                </Checkbox>
                {comParticipante && !podeDividir && (
                  <>
                    <Form.Item name="participante_nome" label="Nome do Participante">
                      <Input placeholder="Nome do Participante" style={{ marginTop: 10 }} />
                    </Form.Item>
                    <Form.Item name="participante_comissao" label="Comissão do Participante">
                      <InputNumber
                        placeholder="Comissão do Participante"
                        step={0.1}
                        min={0}
                        formatter={currencyFormatter}
                        parser={currencyParser}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </>
                )}
              </div>

              <div style={{ marginBottom: 10 }}>
                <Checkbox
                  checked={comParceiro}
                  disabled={podeDividir || comParticipante}
                  onChange={() => setComParceiro(!comParceiro)}
                >
                  Venda feita com parceiro externo?
                </Checkbox>
                {comParceiro && !podeDividir && (
                  <>
                    <Form.Item name="parceiro_nome" label="Nome do parceiro">
                      <Input placeholder="Nome do parceiro" style={{ marginTop: 10 }} />
                    </Form.Item>
                    <Form.Item name="parceiro_comissao" label="Comissão do Parceiro">
                      <InputNumber
                        placeholder="Comissão do Parceiro"
                        step={0.1}
                        min={0}
                        formatter={currencyFormatter}
                        parser={currencyParser}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </>
                )}
              </div>

              <div style={{ marginBottom: 10 }}>
                <Checkbox
                  checked={dividirComissaoAg}
                  disabled={comParceiro || !podeDividir || comParticipante}
                  onChange={() => setDividirComissaoAg(!dividirComissaoAg)}
                >
                  Dividir comissão do vendedor com o agenciador? (50% / 50%)
                </Checkbox>
              </div>

              <Form.Item>
                <Button disabled={loading} htmlType="submit" size="default" type="primary">
                  {loading ? 'Registrando...' : editar ? 'Editar venda' : 'Registrar venda'}
                </Button>
              </Form.Item>

              {!editar && (
                <ButtonGroup style={{ width: '100%' }}>
                  <Button
                    disabled={loading}
                    onClick={vendidoPelaConcorrencia}
                    size="default"
                    type="dashed"
                    style={{ width: '50%' }}
                  >
                    Vendido pela concorrência
                  </Button>
                  <Button
                    disabled={loading}
                    onClick={proprietarioDesistiu}
                    size="default"
                    type="dashed"
                    style={{ width: '50%' }}
                  >
                    Proprietário desistiu
                  </Button>
                </ButtonGroup>
              )}
            </Form>
          </BasicFormWrapper>
        </AddUser>
      </FixStyles>
    </Modal>
  );
};

const FixStyles = styled.div`
  & input,
  & .ant-input-number {
    height: 46px !important;
  }
`;

export default RegistrarVendaModal;

import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, message, DatePicker, Select } from 'antd';
import { BasicFormWrapper } from '../styled';
import { Button } from '../../../components/buttons/buttons';
import { AddUser } from '../../pages/style';
import { Modal } from '../../../components/modals/antd-modals';
import FiltrarClientes from '../../../components/filtros-smart/clientes';
import AppContext from '../../../context';
import FiltrarImoveis from '../../../components/filtros-smart/imoveis';
import styled from 'styled-components';
import locale from 'antd/es/date-picker/locale/pt_BR';

import 'moment/locale/pt-br';

const { Option } = Select;

interface RegistrarPropostaModalProps {
  onCancel: () => void;
  visible: boolean;
  onFinish?: (id: string, proposta?: any) => void;
  imovel_id?: string;
  cliente_id?: string;
  funilPersonalizado?: boolean;
  etapaFunilId?: string | null;
}

const RegistrarPropostaModal = ({
  onCancel,
  visible,
  onFinish,
  imovel_id,
  cliente_id,
  funilPersonalizado,
  etapaFunilId,
}: RegistrarPropostaModalProps) => {
  const context = useContext(AppContext);
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [cliente, setCliente] = useState<{ value?: string; label?: string } | null>(null);
  const [imovel, setImovel] = useState<any>(null);
  const [etapa, setEtapa] = useState<any>(null);
  const [etapas, setEtapas] = useState<any[]>([]);

  const fetchEtapas = async () => {
    try {
      const order = [
        {
          field: 'etapa.ordem',
          order: 'ASC',
        },
      ];
      const params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
        order: JSON.stringify(order),
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/cliente-etapas?${params.toString()}`);

      if (!response.ok) {
        throw new Error('Falha ao buscar etapas.');
      }

      const data = await response.json();
      setEtapas(data);
    } catch (error) {
      console.error(error);
      message.error('Erro ao buscar etapas.');
    }
  };

  useEffect(() => {
    if (visible) {
      fetchEtapas();
    }

    if (!visible) {
      form.resetFields();
      setCliente(null);
      setImovel(null);
      setEtapa(null);
    }
  }, [visible]);

  const handleOk = async (values: any) => {
    setLoading(true);
    try {
      let etapa_funil_ao_cadastrar_proposta_id = values.etapa_funil_id || context?.empresa?.etapa_funil_ao_cadastrar_proposta_id
      if (funilPersonalizado && etapaFunilId && !values.etapa_funil_id) {
        etapa_funil_ao_cadastrar_proposta_id = etapaFunilId
      }
      
      const body = {
        edited_by: context.user?.db_id,
        descrição: values.descrição || 'Sem descrição',
        validade: values.validade.toDate(),
        imovel_id: imovel_id || imovel?.value,
        cliente_id: cliente_id || cliente?.value,
        agenciador_id: context.user?.db_id,
        empresa_id: context.empresa?.db_id,
        etapa_funil_ao_cadastrar_proposta_id,
      };

      const response = await fetch(`${process.env.REACT_APP_API_URI}/propostas`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        const errorText = await response.json();
        throw new Error(errorText.message || 'Erro ao registrar proposta.');
      }

      const proposta = await response.json();
      form.resetFields();
      message.success('Proposta registrada com sucesso!');

      if (onFinish) {
        onFinish(cliente_id || cliente?.value || '', proposta);
      }

      onCancel();
    } catch (error: any) {
      console.error(error);
      message.error(error.message || 'Erro ao registrar proposta!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal type={'primary'} title="Cadastro de proposta" visible={visible} footer={null} onCancel={onCancel}>
      <FixStyles className="project-modal">
        <AddUser>
          <BasicFormWrapper>
            <Form form={form} name="proposta" onFinish={handleOk} layout="vertical">
              {!cliente_id && (
                <Form.Item
                  label="Cliente"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, selecione um cliente!',
                    },
                  ]}
                >
                  <FiltrarClientes
                    style={{ height: '100%' }}
                    cliente_obj={cliente}
                    handleChangeCliente={setCliente}
                    empresa_id={context.empresa.db_id}
                  />
                </Form.Item>
              )}

              {!imovel_id && (
                <Form.Item
                  label="Código Imóvel"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, selecione um imóvel!',
                    },
                  ]}
                >
                  <FiltrarImoveis
                    placeholder={'Código do Imóvel'}
                    style={{ height: '100%' }}
                    imovel_obj={imovel}
                    handleChangeImovel={setImovel}
                    empresa_id={context.empresa.db_id}
                  />
                </Form.Item>
              )}

              {!(context?.empresa?.etapa_funil_ao_cadastrar_proposta_id || etapaFunilId) && (
                <Form.Item
                  name="etapa_funil_id"
                  label="Etapa Funil"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, selecione uma etapa do funil!',
                    },
                  ]}
                >
                  <Select
                    style={{ width: '100%' }}
                    allowClear
                    showSearch
                    filterOption={(input, option) => option?.children.toLowerCase().includes(input.toLowerCase())}
                    className="height-fix"
                    onChange={e => setEtapa(e || null)}
                    placeholder={'Etapa do funil'}
                    value={etapa || undefined}
                    size="large"
                  >
                    {etapas.map(etapa => (
                      <Option key={etapa.id} value={etapa.id}>
                        {etapa.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              <Form.Item
                name="descrição"
                label="Descrição da proposta"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, descreva a proposta!',
                  },
                ]}
              >
                <Input placeholder="Descreva os detalhes da proposta" />
              </Form.Item>

              <Form.Item
                name="validade"
                label="Data de validade da proposta"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, selecione a data de validade da proposta!',
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  locale={locale}
                  placeholder="Selecione a data de validade"
                  style={{ width: '100%' }}
                />
              </Form.Item>

              <Form.Item>
                <Button disabled={loading} htmlType="submit" size="default" type="primary" key="submit">
                  Registrar proposta!
                </Button>
              </Form.Item>
            </Form>
          </BasicFormWrapper>
        </AddUser>
      </FixStyles>
    </Modal>
  );
};

const FixStyles = styled.div`
  & input {
    height: 46px !important;
  }
`;

export default RegistrarPropostaModal;

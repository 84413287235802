import { WhatsAppOutlined } from "@ant-design/icons";
import React from "react";

export const isValidPhoneNumber = (phone: string) => {
  const cleaned = phone.replace(/\D/g, "");
  const withoutCountryCode = cleaned.startsWith("55")
    ? cleaned.slice(2)
    : cleaned;
  return withoutCountryCode.length === 11;
};

const formatPhoneNumber = (phone: string) => {
  if (!phone) return phone;
  const cleaned = ("" + phone).replace(/\D/g, "");
  const withoutCountryCode = cleaned.startsWith("55")
    ? cleaned.slice(2)
    : cleaned;
  const match = withoutCountryCode.match(/^(\d{2})(\d{5})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phone;
};

export const renderPhoneNumber = (phone: string, ddd?: string) => {
  const cleanedPhone = phone.replace(/\D/g, "");
  let fullPhone = cleanedPhone;

  if (ddd && !cleanedPhone.startsWith(ddd)) {
    fullPhone = ddd + cleanedPhone;
  }

  if (isValidPhoneNumber(fullPhone)) {
    const formattedPhone = formatPhoneNumber(fullPhone);
    const whatsappLink = `https://wa.me/55${fullPhone}`;
    return (
      <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
        {formattedPhone}{" "}
        <WhatsAppOutlined style={{ color: "#25D366", marginLeft: 5 }} />
      </a>
    );
  }

  return phone;
};